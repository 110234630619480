import { NgModule } from '@angular/core';
import { ReportViewerComponent } from './reportviewer.component';



@NgModule({
  declarations: [ReportViewerComponent],
  imports: [
  ],
  exports: [ReportViewerComponent]
})
export class ReportViewerModule { }
